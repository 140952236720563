import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import { useFormContext } from 'react-hook-form'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import TextareaAutosize from 'react-textarea-autosize'
import { classNames } from 'lib/shared-ui'
import type { ValidationsProps } from './validation-props'

export interface TextareaProps {
    id: string
    defaultValue?: string
    placeholder?: string
    disabled?: boolean
    className?: string
    rules?: Omit<ValidationsProps, 'valueAsDate'>
}

export const Textarea: React.FC<TextareaProps> = ({
    id,
    defaultValue,
    placeholder,
    className,
    rules,
    disabled,
}) => {
    const {
        register,
        formState: { errors },
        watch,
    } = useFormContext()

    const value = watch(id)
    const isError = Boolean(errors[id])
    const isWithCounter = rules?.minLength || rules?.maxLength

    return (
        <div className="relative">
            <TextareaAutosize
                disabled={disabled}
                minRows={2}
                className={twMerge(
                    classNames(
                        isError
                            ? 'pr-10 border-red-300 text-red-900 focus:border-red-500 placeholder-red-300 focus:ring-red-500'
                            : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500',
                        disabled
                            ? 'cursor-not-allowed !placeholder-gray-400'
                            : '',
                        'block w-full focus:outline-none text-sm rounded-md py-2',
                        className
                    )
                )}
                placeholder={placeholder}
                aria-invalid={isError ? 'true' : 'false'}
                aria-describedby={isError ? `${id}-error` : `${id}-input`}
                {...register(id, rules)}
            >
                {defaultValue}
            </TextareaAutosize>
            {isWithCounter && (
                <div className="absolute right-1 bottom-1 pr-1 flex items-center pointer-events-none">
                    <span
                        className="text-sm text-gray-500"
                        id={`${id}-counter`}
                    >
                        {rules?.minLength && value
                            ? `${value.length}/${rules.minLength}+`
                            : rules?.maxLength && value
                            ? `${value.length}/${rules.maxLength}`
                            : ''}
                    </span>
                </div>
            )}
            {isError && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                    />
                </div>
            )}
        </div>
    )
}
