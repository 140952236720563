import * as React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { classNames } from 'lib/shared-ui'
import { ValidationsProps } from './validation-props'

interface Option {
    id: string | number
    Icon?: React.FC<React.ComponentProps<'svg'>>
    title: string
    description?: string
    value?: string
    disabled?: boolean
}

export interface RadioSwitchProps {
    id: string
    options: Option[]
    defaultValue?: string | number
    perRow?: string
    rules?: Omit<ValidationsProps, 'valueAsDate'>
}

export const RadioSwitch: React.FC<RadioSwitchProps> = ({
    id,
    options,
    rules,
    perRow,
    defaultValue,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    const {
        field: { onChange, value },
    } = useController({
        name: id,
        control,
        rules,
        defaultValue,
    })

    const isError = Boolean(errors[id])
    const isSwitch = options.length < 3

    return (
        <div className="mt-2 relative">
            <RadioGroup value={value} onChange={onChange}>
                <div
                    className={classNames(
                        perRow || 'grid-cols-1 sm:grid-cols-3',
                        'grid sm:gap-y-4 sm:gap-x-4 border sm:border-0 border-gray-300 rounded-lg sm:rounded-unset overflow-hidden bg-neutral-200 sm:bg-transparent'
                    )}
                >
                    {options.map((option) => (
                        <RadioGroup.Option
                            key={`${option.id}${option.value}`}
                            value={option.id}
                            disabled={option.disabled}
                            className={({ checked, active }) =>
                                classNames(
                                    checked
                                        ? 'border-indigo-600 sm:border-2 m-0.5 bg-white shadow rounded-md'
                                        : 'sm:border border-gray-300',
                                    isSwitch ? 'divide-x' : 'divide-y',
                                    'relative sm:rounded-lg px-3 sm:px-4 sm:shadow-sm py-1.5 sm:p-2.5 flex cursor-pointer focus:outline-none divide-y divide-gray-300'
                                )
                            }
                        >
                            {({ checked, active }) => (
                                <>
                                    <div
                                        className={classNames(
                                            isSwitch ? '' : 'pl-7 sm:pl-0',
                                            !isSwitch && checked
                                                ? 'pl-7'
                                                : 'sm:pl-0',
                                            'flex flex-row gap-2 sm:gap-3 pr-1 items-center overflow-hidden'
                                        )}
                                    >
                                        {option.Icon && (
                                            <div className="flex flex-none">
                                                <option.Icon className="h-4 w-4 sm:h-5 sm:w-5" />
                                            </div>
                                        )}
                                        <div className="flex flex-col grow sm:basis-full shrink-0">
                                            <RadioGroup.Label
                                                as="span"
                                                className={classNames(
                                                    'flex text-sm font-medium text-gray-900'
                                                )}
                                            >
                                                {option.title}
                                            </RadioGroup.Label>
                                            {option.description && (
                                                <RadioGroup.Description
                                                    as="span"
                                                    className="mt-1 flex items-center text-sm text-gray-500"
                                                >
                                                    {option.description}
                                                </RadioGroup.Description>
                                            )}
                                            {option.value && (
                                                <RadioGroup.Description
                                                    as="span"
                                                    className="mt-3 text-sm font-medium text-gray-900"
                                                >
                                                    {option.value}
                                                </RadioGroup.Description>
                                            )}
                                        </div>
                                        <CheckCircleIcon
                                            className={classNames(
                                                !checked
                                                    ? 'text-gray-200'
                                                    : 'text-indigo-600',
                                                isSwitch && !checked
                                                    ? 'hidden'
                                                    : '',
                                                isSwitch && checked
                                                    ? 'right-3'
                                                    : '',
                                                !isSwitch
                                                    ? 'left-3 sm:right-3 sm:left-auto'
                                                    : '',
                                                !isSwitch && !checked
                                                    ? 'sm:hidden'
                                                    : '',
                                                'h-4 sm:h-5 sm:mt-0 absolute top-1/2 transform -translate-y-1/2'
                                            )}
                                            aria-hidden="true"
                                        />
                                        {/*<div*/}
                                        {/*    className={classNames(*/}
                                        {/*        checked*/}
                                        {/*            ? 'border-indigo-500'*/}
                                        {/*            : 'border-transparent',*/}
                                        {/*        'absolute -inset-px sm:rounded-lg pointer-events-none sm:border-2'*/}
                                        {/*    )}*/}
                                        {/*    aria-hidden="true"*/}
                                        {/*/>*/}
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            {isError && (
                <div className="absolute inset-y-0 left-0 pr-3 flex items-start pointer-events-none">
                    <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                    />
                </div>
            )}
        </div>
    )
}
